import { Controller } from "@hotwired/stimulus"

function getFirstFocusableFrom(target) {
  const matches = target.querySelectorAll('button, [href], input, [tabindex="0"]')

  if (matches.length > 0) {
    return matches[0]
  }

  return null
}

export default class extends Controller {
  static targets = [ "autofocus", "autofocusWithin" ]

  autofocusTargetConnected(target) {
    target.focus()
  }

  autofocusWithinTargetConnected(target) {
    const firstFocusable = getFirstFocusableFrom(target)
    
    if (firstFocusable) {
      firstFocusable.focus()
    }
  }

  markForRestoration({ target }) {
    this.focusRestorationElement = target
  }

  restoreFocus({ detail: { success } }) {
    if (this.focusRestorationElement && this.focusRestorationElement.isConnected && !success) {
      this.focusRestorationElement.focus()
    }

    // Don't bring this in until we need it
    // this.dispatch('focusRestored')
  }
}
